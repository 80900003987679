import { Injectable } from "@angular/core";
import { SignInAuthenticationStore } from "./sign-in-authentication.store";
import { NiceAuthenticationService } from "@recursyve/nice-auth0-kit";
import { catchError, EMPTY, Observable, of, switchMap } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { UserOrganizationService } from "../../../../providers/user-organization.service";
import { environment } from "../../../../../environments/environment";
import { AppService } from "../../../../store/app.service";
import { SignInAuthenticationQuery } from "./sign-in-authentication.query";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";
import { AuthProviders } from "../../../../api/nordicite/organizations/models/organizations.model";
import { ApiService } from "../../../../api/api.service";
import { filter, map } from "rxjs/operators";
import { niceSetLoadingAndError } from "../../../../akita/pipes/nice-set-loading-and-error.pipe";
import * as Sentry from "@sentry/angular";

@Injectable()
export class SignInAuthenticationService {
    private redirectUri = environment.applicationUrl + environment.applicationOauthPath;

    constructor(
        private store: SignInAuthenticationStore,
        private query: SignInAuthenticationQuery,
        private niceAuthService: NiceAuthenticationService,
        private userOrganizationService: UserOrganizationService,
        private activatedRoute: ActivatedRoute,
        private toastService: NiceToastService,
        private appService: AppService,
        private apiService: ApiService,
        private router: Router,
        private window: Window
    ) {}

    public reset(): void {
        this.store.reset();
        this.userOrganizationService.clearCurrentOrganization();
    }

    public resetError(): void {
        this.store.setError(null);
    }

    public init(code?: string): Observable<{ isAuthenticated: boolean }> {
        return this.userOrganizationService.loadUserOrganization().pipe(
            niceSetLoadingAndError(this.store),
            map(userOrganization => {
                this.store.update({
                    organizationSlug: userOrganization?.slug,
                    authConnection: userOrganization?.auth0ConnectionName,
                    authProvider: userOrganization?.authProvider ?? AuthProviders.Password
                });
                return code;
            }),
            filter(code => !!code),
            switchMap(code => this.signInWithAuthCode(code)),
            map(isAuthenticated => ({ isAuthenticated }))
        );
    }

    public signInWithConnection(connection: string): Observable<void> {
        return this.niceAuthService
            .getAuthFlowRequestUri(this.redirectUri, { connection })
            .pipe(map(uri => this.window.location.assign(uri)));
    }

    public signInWithCredentials(email: string, password: string): Observable<boolean> {
        return this.niceAuthService.passwordGrant(email.trim(), password).pipe(
            niceSetLoadingAndError(this.store),
            filter(response => !!response.success),
            switchMap(() => this.validateAccess()),
            catchError(() => {
                this.store.setError({ invalid_credentials: true });
                return EMPTY;
            })
        );
    }

    public signInWithAuthCode(code: string): Observable<boolean> {
        return this.niceAuthService.authorizationCodeGrant(code, this.redirectUri).pipe(
            filter(response => !!response.success),
            switchMap(() => this.validateAccess()),
            catchError(() => {
                this.store.setError({ invalid_credentials: true });
                return EMPTY;
            })
        );
    }

    private validateAccess(): Observable<boolean> {
        return this.appService.loadCurrentAccount().pipe(
            switchMap(account => {
                if (!account) {
                    return this.validateAccountRegistration();
                }

                const { organizationSlug } = this.query.getValue();

                if (organizationSlug && account.organization?.slug?.toLowerCase() !== organizationSlug.toLowerCase()) {
                    Sentry.captureMessage(
                        `Signin Service - validateAccess -
                        account : ${account} --- organizationSlug: ${organizationSlug}`,
                        "error"
                    );
                    return this.logoutForAccessDenied();
                }

                if (!organizationSlug && account.organization?.slug) {
                    return this.userOrganizationService
                        .setUserOrganization(account.organization.slug)
                        .pipe(map(() => true));
                }
                return of(true);
            })
        );
    }

    private logoutForAccessDenied(): Observable<boolean> {
        return this.appService.logout().pipe(
            map(() => {
                this.toastService.error(
                    "features.sign_in.authentication.organization_access_denied",
                    "features.sign_in.authentication.invalid_credentials"
                );
                return false;
            })
        );
    }

    private validateAccountRegistration(): Observable<boolean> {
        const state = this.query.getValue();
        if (state.authProvider === AuthProviders.Password) {
            return of(false);
        }

        return this.apiService.register
            .registerProviderAccount({ organizationSlug: state.organizationSlug })
            .pipe(map(created => !!created));
    }
}
