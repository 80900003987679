<ng-container
    *ngIf="{
        appearance: appearance$ | async,
        navBarIsCollapsed: navBarIsCollapsed$ | async,
        account: currentAccount$ | async,
        user: currentUser$ | async,
        isScreenSmall: isScreenSmall$ | async
    } as ctx"
>
    <nice-vertical-navigation
        class="print:hidden"
        [name]="'mainNavigation'"
        [opened]="opened"
        [appearance]="ctx.appearance"
        (hoveredChanged)="this.hovered$.next($event)"
    >
        <ng-container niceVerticalNavigationHeader>
            <div class="flex flex-col items-center" [formGroup]="formGroup">
                <div class="grid place-items-start [grid-template-areas:'stack']">
                    <div
                        class="pb-10 flex justify-between items-center py-3 pl-6 pr-4 transition-opacity [grid-area:stack]"
                        [ngClass]="ctx.navBarIsCollapsed ? 'opacity-0' : 'opacity-100'"
                    >
                        <a [routerLink]="imageRouterLink$ | async">
                            <img
                                class="logo cursor-pointer pt-4"
                                ngSrc="../../../../../assets/images/logo.svg"
                                alt="Nordicité logo"
                                height="140"
                                width="300"
                            />
                        </a>

                        <button *ngIf="!ctx.isScreenSmall" mat-icon-button class="ml-2" (click)="clickNavbarMenu()">
                            <mat-icon>menu</mat-icon>
                        </button>
                    </div>

                    <a class="[grid-area:stack]" [routerLink]="imageRouterLink$ | async">
                        <img
                            class="logo cursor-pointer pt-4 transition-opacity w-15 pl-5"
                            [ngClass]="!ctx.navBarIsCollapsed ? 'opacity-0' : 'opacity-100'"
                            ngSrc="../../../../../assets/images/logo-waves.svg"
                            alt="Nordicité logo"
                            height="97"
                            width="209"
                        />
                    </a>
                </div>
                <div
                    *ngIf="currentOrganization$ | async as currentOrganization"
                    class="grid place-items-start [grid-template-areas:'stack'] mb-6 w-full"
                >
                    <div
                        *ngIf="userHasMultipleOrganizations$ | async"
                        class="flex flex-col justify-center items-center transition-opacity [grid-area:stack] px-8"
                        [ngClass]="ctx.navBarIsCollapsed ? 'opacity-0' : 'opacity-100'"
                    >
                        <mat-form-field [appearance]="$any('none')" class="organization-name">
                            <nice-async-typeahead
                                class="text-lg w-full"
                                formControlName="organizationId"
                                [placeholder]="'navigation.organization_name' | translate"
                                resource="organizations"
                                [searchOptions]="{ fromAccount: true }"
                            ></nice-async-typeahead>
                        </mat-form-field>
                        <mat-divider class="w-full"></mat-divider>
                    </div>
                    <ng-template [ngIf]="(userHasMultipleOrganizations$ | async) === false">
                        <h3
                            class="organization-name [grid-area:stack] transition-opacity"
                            [ngClass]="ctx.navBarIsCollapsed ? 'opacity-0' : 'opacity-100'"
                        >
                            {{ currentOrganization.name }}
                        </h3>
                    </ng-template>
                    <div
                        class="flex flex-col justify-center items-center transition-opacity [grid-area:stack] w-15 pl-5"
                        [ngClass]="!ctx.navBarIsCollapsed ? 'opacity-0' : 'opacity-100'"
                    >
                        <img
                            [src]="currentOrganization?.logoUrl"
                            class="rounded"
                            nordiciteImageOnErrorFallback
                            [fallback]="imagePlaceholder"
                            alt="{{ currentOrganization?.name }}'s logo"
                        />

                        <ng-template #imagePlaceholder>
                            <nordicite-image-placeholder class="h-10 w-10"></nordicite-image-placeholder>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container niceVerticalNavigationFooter>
            <div
                *ngIf="(currentAccount$ | async)?.role?.role !== RolesEnum.SuperAdmin"
                class="p-4 menu-button flex items-center justify-center flex-auto flex-grow-0 flex-row w-full"
            >
                <button [matMenuTriggerFor]="userActions" class="w-full justify-start" mat-button>
                    <div class="w-full grid place-items-start">
                        <div
                            class="col-start-1 row-start-1 transition-opacity flex justify-center items-center w-full"
                            [ngClass]="ctx.navBarIsCollapsed ? 'opacity-0' : 'opacity-100'"
                        >
                            <div class="flex flex-col justify-center items-center pr-4">
                                <img
                                    [src]="ctx.account?.pictureUrl ?? ctx.user?.picture"
                                    class="h-8 w-8 rounded-full"
                                    nordiciteImageOnErrorFallback
                                    [fallback]="fallbackProfilePicture"
                                    alt="{{ ctx.account?.fullName }}'s profile picture"
                                />

                                <ng-template #fallbackProfilePicture>
                                    <mat-icon
                                        class="icon-size-8"
                                        svgIcon="streamline-regular:single-neutral-circle"
                                    ></mat-icon>
                                </ng-template>
                            </div>
                            <span>{{ ctx.account?.fullName }}</span>
                        </div>
                        <div
                            class="col-start-1 row-start-1 transition-opacity"
                            [ngClass]="!ctx.navBarIsCollapsed ? 'opacity-0' : 'opacity-100'"
                        >
                            <img
                                [src]="ctx.account?.pictureUrl ?? ctx.user?.picture"
                                class="h-8 w-8 rounded-full"
                                nordiciteImageOnErrorFallback
                                [fallback]="fallbackProfilePicture"
                                alt="{{ ctx.account?.fullName }}'s profile picture"
                            />

                            <ng-template #fallbackProfilePicture>
                                <mat-icon
                                    class="icon-size-8"
                                    svgIcon="streamline-regular:single-neutral-circle"
                                ></mat-icon>
                            </ng-template>
                        </div>
                    </div>
                </button>

                <mat-menu class="user-menu" [xPosition]="'before'" #userActions="matMenu">
                    <div *ngIf="ctx.account" class="h-auto py-4 px-6" niceClickStopPropagation>
                        <div class="flex flex-col overflow-hidden leading-none">
                            <span
                                class="inline-block max-w-full overflow-hidden overflow-ellipsis font-medium leading-normal"
                            >
                                {{ ctx.account.fullName }}
                            </span>
                            <span
                                class="mt-1.5 inline-block max-w-full overflow-hidden overflow-ellipsis pb-0.5 leading-normal"
                            >
                                {{ ctx.account.email }}
                            </span>
                        </div>
                    </div>
                    <mat-divider class="m-0"></mat-divider>
                    <button mat-menu-item (click)="logout()">
                        <mat-icon svgIcon="streamline-regular:logout-1" class="h-5 min-h-5 w-5 min-w-5"></mat-icon>
                        <span>{{ "features.dashboard.components.toolbar.sign_out" | translate }}</span>
                    </button>
                </mat-menu>
            </div>
        </ng-container>
    </nice-vertical-navigation>
</ng-container>
