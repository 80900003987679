import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { _NordiciteBaseApi } from "../nordicite-base.api";
import { Observable } from "rxjs";
import { Equipments } from "./models/equipments.model";
import { UpdateActiveStatusDto } from "../organizations/dto/update-active-status.dto";
import { EquipmentAttributes } from "./models/equipment-attributes.model";
import { EquipmentEntriesApi } from "./equipment-entries.api";
import { CreateEquipmentAttributeDto } from "./dto/create-equipment-attribute.dto";
import { BreadcrumbItems } from "@recursyve/ngx-panko";
import { UpdateEquipmentRankDto } from "./dto/update-equipment-rank.dto";
import { UpdateEquipmentDto } from "./dto/update-equipment.dto";
import { GetEquipmentsDto } from "./dto/get-equipments.dto";
import { GetEquipmentDto } from "./dto/get-equipment.dto";

@Injectable()
export class EquipmentsApi extends _NordiciteBaseApi {
    constructor(http: HttpClient) {
        super("equipment", http);
    }

    public entries(equipmentId: number): EquipmentEntriesApi {
        return new EquipmentEntriesApi(equipmentId, this.httpClient);
    }

    public createEquipmentAttribute(
        equipmentId: number,
        dto: CreateEquipmentAttributeDto
    ): Observable<EquipmentAttributes> {
        return this.post<EquipmentAttributes>(`${equipmentId}/attribute`, dto);
    }

    public getById(id: number, dto?: GetEquipmentDto): Observable<Equipments> {
        return this.get(`${id}`, { params: { ...dto }, type: Equipments });
    }

    public getAll(dto: GetEquipmentsDto): Observable<Equipments[]> {
        return this.get("", {
            params: { ...dto },
            type: Equipments
        });
    }

    public getBreadcrumbById(id: number): Observable<BreadcrumbItems[]> {
        return this.get(`${id}/breadcrumb`);
    }

    public updateEquipment(id: number, dto: UpdateEquipmentDto): Observable<Equipments> {
        return this.put(`${id}`, dto);
    }

    public updateActiveStatus(id: number, dto: UpdateActiveStatusDto): Observable<Equipments> {
        return this.put(`${id}/status`, dto);
    }

    public updateEquipmentRank(id: number, dto: UpdateEquipmentRankDto): Observable<Equipments> {
        return this.put(`${id}/rank`, dto);
    }
}
