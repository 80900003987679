import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { Integrations } from "@sentry/tracing";
import { registerLicense } from "@syncfusion/ej2-base";
import { enableAkitaProdMode } from "@datorama/akita";
import { enableRipple } from "@syncfusion/ej2-base";

enableRipple(true);

Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.sentryEnvironment,
    release: environment.sentryRelease,
    beforeSend: (event: Sentry.Event, _) => {
        console.log(event.exception);

        if (environment.sentryReportErrors) {
            return event;
        }

        return null;
    },
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: [environment.apiUrl],
            routingInstrumentation: Sentry.routingInstrumentation
        })
    ]
});

registerLicense(environment.syncfusionLicenseKey);

if (environment.production) {
    enableProdMode();
    enableAkitaProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
